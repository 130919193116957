import { fetchUserSessionOrTimeout } from '@/auth/user-store';
import { get, isEmpty, round } from 'lodash-es';
import { debounce as debounceAsync } from 'perfect-debounce';
import { ZodIssue, z } from 'zod';
import { reportSentryError, reportSentryWarning } from './sentry';

//
// Utility for making requests to APIs (typically REST APIs returning JSON)
//

type RequestAPIParams<T> = {
  url: string | URL;
  method?: 'GET' | 'POST' | 'HEAD' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: Record<string, string>;
  body?: BodyInit;
  abortSignal?: AbortSignal;
  // How many seconds to wait before reporting Sentry warning or aborting request
  timeout?: { warn?: number; abort?: number };
  // Return valid object or throw error
  validate?: (response: unknown, status: number) => T;
  // Whether to reject if validation fails (defaults to true for custom validation and false for Zod validation)
  rejectOnValidationError?: boolean;
  // In certain cases we expect an error (e.g. 404, 400, etc.) and don't want to report it in Sentry
  // Treat these statuses the same as a 2XX ones (run validation on the JSON body)
  expectedStatusCodes?: number[];
  // In certain cases we expect an error (e.g. 404, 400, etc.) and don't want to report it in Sentry
  // Allow handling specific statuses / errors and provide fallback values
  handleNotOkResponse?: (
    status: number,
    responseJSON: unknown,
    responseText: string | undefined
  ) => T | undefined;
};

type ErrorCause = {
  reason: string;
  shouldReject: boolean;
  reportLevel: 'error' | 'warning';
  status?: number;
  issues?: ZodIssue[];
  error?: unknown;
};

// Fetch URL and expect JSON that passes validation
export async function requestApi<T>({
  url,
  method = 'GET',
  headers,
  body,
  validate,
  abortSignal,
  timeout = getDefaultTimeout(method),
  rejectOnValidationError,
  expectedStatusCodes = [],
  handleNotOkResponse,
}: RequestAPIParams<T>): Promise<T> {
  if (typeof url === 'string') url = new URL(url);
  let res;
  let isLoading = true;
  const startTime = Date.now();

  // Report warning if request takes longer than expected
  if (timeout && timeout.warn) {
    setTimeout(() => {
      if (isLoading) {
        const seconds = Math.round(timeout.warn! / 1000);
        reportSentryWarning(
          new Error(`Request taking longer than ${seconds}s: ${(url as URL).pathname}`)
        );
      }
    }, timeout.warn);
  }

  // Abort if request takes too long
  if (timeout && timeout.abort) {
    const timeoutSignal = crossBrowserAbortSignalTimeout(timeout.abort);
    abortSignal = abortSignal ? anySignal([abortSignal, timeoutSignal]) : timeoutSignal;
  }

  try {
    res = await fetch(url, { method, headers, body, signal: abortSignal });
    isLoading = false;
  } catch (error) {
    isLoading = false;
    // Log request failures due to CORS issues or network issues, but don't send to
    // Sentry b/c these are inevitable and expected. They will still show up in Sentry
    // breadcrumbs for any downstream errors
    // Ignore aborted requests entirely, as this may be done programmatically with
    // AbortController when we no longer need a response, or on user page navigation, etc.
    const wasAborted = Boolean(error && (error as Error).name === 'AbortError');
    if (!wasAborted) {
      console.warn(`${method} request failed: ${url.pathname}`, {
        status: '(none)',
        url: url.toString(),
        requestBody: body,
        duration: round((Date.now() - startTime) / 1000, 1),
        error: error,
      });
    }
    // If user is offline, prompt them to check their internet connection
    if (!navigator.onLine && error instanceof Error) {
      error.message += '. Please check your internet connection.';
    }
    // Note: when a request times out, maybe we should throw a more helpful exception than "DOMException: signal timed out"? (Chrome 124, varies by browser)
    throw error;
  }

  let responseText: string | undefined;
  let responseJSON;
  let hasJSONBody = false;
  try {
    // We can only get either text or json, since stream will be consumed if we attempt to get both,
    // so first get text, and then attempt to parse it as JSON
    responseText = await res.text();
    responseJSON = JSON.parse(responseText);
    hasJSONBody = true;
  } catch (err1) {
    /* empty */
  }

  // Simulate latency when debugging locally
  // if (document.domain === 'localhost') await waitForMs(1000);

  const hasErrorStatus = !res.ok && !expectedStatusCodes.includes(res.status);

  // Allow caller to handle a non-OK response and provide a fallback value instead of
  // rejecting and reporting error in Sentry
  if (!res.ok && handleNotOkResponse) {
    const result = handleNotOkResponse(res.status, responseJSON, responseText);
    if (result !== undefined) return result;
  }

  let errorCause: ErrorCause | undefined;
  if (hasErrorStatus) {
    errorCause = {
      reason: `Unexpected status code`,
      status: res.status,
      // Always reject if status code is not in the 200's
      shouldReject: true,
      reportLevel: 'warning',
    };
  } else if (validate) {
    try {
      validate(responseJSON, res.status);
    } catch (validationError) {
      if (validationError instanceof z.ZodError) {
        errorCause = {
          reason: `Response failed Zod validation: ${
            validationError.issues.length
          } issue(s) ${validationError.issues[0]?.path.join('.')}`,
          // If we're requesting 1000 records and they all fail, don't send 1000 issues to Sentry, 10 is plenty.
          issues: validationError.issues.slice(0, 10),
          // By default, do not reject if Zod validation fails b/c it's typically reflective
          // of a strict OpenApi schema and chances are the response is still usable
          shouldReject: rejectOnValidationError === true,
          reportLevel: rejectOnValidationError === true ? 'error' : 'warning',
        };
      } else {
        errorCause = {
          reason: 'Response failed custom validation',
          error: validationError,
          // By default, reject if custom validation fails
          shouldReject: rejectOnValidationError !== false,
          reportLevel: rejectOnValidationError !== false ? 'error' : 'warning',
        };
      }
    }
  }

  // Status code not in range 200-299 or data failed validation
  if (errorCause) {
    // Report an error if validation of API response failed and client has chosen to reject invalid responses
    // Otherwise, report a warning for all other scenarios (e.g. API 4xx or 5xx error, validation failed but client will attempt to use it anyway)
    const reportingFunction =
      errorCause.reportLevel === 'error' ? reportSentryError : reportSentryWarning;
    const errorToReport = hasErrorStatus
      ? // Note: creating errors on separate lines may help Sentry avoid grouping them by stack trace
        new Error(`API ${method} failed with ${res.status}: ${url.pathname}:`)
      : new Error(`API ${method} ${errorCause.reason.replace(/^Response /, '')}: ${url.pathname}:`);
    reportingFunction(errorToReport, {
      url: url.toString(),
      requestBody: body,
      duration: round((Date.now() - startTime) / 1000, 1),
      // Log response from server (parsed JSON w/ text fallback)
      responseBody: hasJSONBody ? responseJSON : responseText,
      // Include data relevant to why it failed
      ...errorCause,
    });

    if (errorCause.shouldReject) {
      const readableErrorFromApi =
        hasErrorStatus && hasJSONBody && get(responseJSON, 'error.message')
          ? `${responseJSON.error.message} (${res.status} Error)`
          : `${res.status} Error`;

      const errorMessage = !hasErrorStatus
        ? // Request succeeded but validation failed
          'Unexpected response from server'
        : // Request failed with non-200 status code
        method === 'GET'
        ? `Failed to fetch data: ${readableErrorFromApi}`
        : `Request failed: ${readableErrorFromApi}`;

      // Reject Promise with custom error
      throw new ResponseError(errorMessage, errorCause, hasJSONBody ? responseJSON : responseText);
    }
  }

  return hasJSONBody ? responseJSON : responseText;
}

//
// ResponseError exposes typed error object from API in case we ever want to take action based on error code
//

const apiErrorSchema = z.object({
  error: z.object({
    code: z.string(),
    message: z.string(),
  }),
});

export class ResponseError extends Error {
  responseBody: unknown;

  constructor(
    message: string,
    cause: { reason: string; [key: string]: unknown },
    responseBody: unknown
  ) {
    super(message);
    Object.setPrototypeOf(this, ResponseError.prototype); // For Typescript

    this.name = 'ResponseError';
    this.responseBody = responseBody;
    this.cause = cause;
  }

  get apiError() {
    try {
      return apiErrorSchema.parse(this.responseBody).error;
    } catch (err) {
      return;
    }
  }
}

// Default timeout settings for requests
export function getDefaultTimeout(method: string) {
  // By default, report warning in Sentry if request takes longer than 15s
  const warn = 15 * 1000;

  // By default, abort GET requests that take over a minute
  const abort = method === 'GET' ? 60 * 1000 : undefined;

  return { warn, abort };
}

export function urlWithParams<T extends string>(
  baseUrl: T,
  paramsObject: Record<string, string | number>
) {
  // Avoid adding `?` if there's no parameters to add
  if (isEmpty(paramsObject)) return baseUrl;

  // @ts-expect-error - it's fine if we pass a number and let it be coerced to a string
  const queryParamsString = new URLSearchParams(paramsObject).toString();
  return `${baseUrl}?${queryParamsString}` as `${T}?${string}`;
}

export async function getAuthHeader() {
  return {
    Authorization: `Bearer ${await getAuthToken()}`,
  };
}

export const getAuthToken = debounceAsync(
  async () => {
    let session;
    try {
      session = await fetchUserSessionOrTimeout();
      return session.tokens!.idToken!.toString();
    } catch (error) {
      // TEMP: debug failure to get session and figure out what best to do in this scenario
      console.warn('Failed to get auth token', {
        session,
        error,
      });
      throw new Error('Not signed-in');
    }
  },
  // No need to wait between updates, the goal is to prevent overlapping async calls from happening
  // e.g. to avoid refreshing JWT token 4 times if it's expired & SWR fires off 4 API requests when
  // user re-enters tab.
  0,
  { leading: true }
);

// Used for simulating latency for local debugging
export function wait(timeoutMs: number) {
  return new Promise((res) => setTimeout(res, timeoutMs));
}

// AbortSignal.timeout() w/ fallback for older browsers
// https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/timeout#browser_compatibility
export function crossBrowserAbortSignalTimeout(timeoutMs: number) {
  if (AbortSignal.timeout) return AbortSignal.timeout(timeoutMs);

  const abortController = new AbortController();
  setTimeout(() => {
    abortController.abort(new Error('Timeout'));
  }, timeoutMs);
  return abortController.signal;
}

// Create signal that is aborted when any of its input signals are aborted
// Source: https://github.com/whatwg/fetch/issues/905#issuecomment-1425708260
function anySignal(signals: AbortSignal[]): AbortSignal {
  const controller = new AbortController();

  for (const signal of signals) {
    if (signal.aborted) return signal;

    signal.addEventListener('abort', () => controller.abort(signal.reason), {
      signal: controller.signal,
    });
  }

  return controller.signal;
}
